<!--
--------------------------------------------------------------------------
   ItemGraph.vue
--------------------------------------------------------------------------

    Component to build a data graph in an item

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div :id="'item-' + (item != null ? item.id : simulation.id) + '-graph'" class="item-graph" v-if="loading === 0 && templatesLoaded">
        <div class="row">
            <div class="col-12 col-md-4">
                <highcharts :options="chartOptions1"></highcharts>
            </div>
            <div class="col-12 col-md-8">
                <highcharts :options="chartOptions2"></highcharts>
            </div>
        </div>
    </div>
</template>

<script>
    import { Chart } from 'highcharts-vue';
    import exportingInit from "highcharts/modules/exporting";
    import exportDataInit from "highcharts/modules/export-data";
    import Highcharts from "highcharts";

    exportingInit(Highcharts);
    exportDataInit(Highcharts);

    export default {
        name: 'item-graph',
        props: ['item', 'simulation'],
        components: { highcharts: Chart },
        data() {
            return {
                loading: -2,
                templatesLoaded: false,
                formData: {
                    query: '*:*',
                    facets: [],
                    page: {
                        pageIdx: 0,
                        pageSize: 1
                    }
                },
                lang: {
                    resetZoom: this.$gettext('View all'),
                    resetZoomTitle: this.$gettext('View all'),
                    decimalPoint: ',',
                    thousandsSep: '.',
                    contextButtonTitle: this.$gettext('Menu'),
                    downloadJPEG: this.$gettext('Download JPEG'),
                    downloadPDF: this.$gettext('Download PDF'),
                    downloadPNG: this.$gettext('Download PNG'),
                    downloadSVG: this.$gettext('Download SVG'),
                    loading: this.$gettext('Loading...'),
                    printChart: this.$gettext('Print'),
                    downloadCSV: this.$gettext('Download CSV'),
                    downloadXLS: this.$gettext('Download XLS (Excel)'),
                    viewData: this.$gettext('View data table'),
                    viewFullscreen: this.$gettext('View in full screen'),
                    hideData: this.$gettext('Hide data table'),
                    exitFullscreen: this.$gettext('Exit from full screen'),
                    numericSymbols: null
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ["printChart",
                                "separator",
                                "downloadPNG",
                                "downloadJPEG",
                                "downloadPDF",
                                "downloadSVG",
                                "separator",
                                "downloadCSV",
                                "downloadXLS",
                                //"viewData",
                                "openInCloud"]
                        }
                    }
                },
                chartOptions1: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    title: {
                        text: this.$gettext('Production by type')
                    },
                    xAxis: {
                        title: {
                            text: this.$gettext('Activity type')
                        },
                        labels: {
                            enabled: false
                        },
                        categories: [
                            this.$gettext('Activity type'),
                        ],
                    },
                    yAxis: {
                        min: 0,
                        allowDecimals: false,
                        title: {
                            text: this.$gettext('Num. activities')
                        }
                    },
                    tooltip: {
                        headerFormat: '',
                        formatter: function() {
                            return this.series.name + ': <b>' + Highcharts.numberFormat(this.y, -1, ',', '.') + '</b>';
                        }
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                rotation: -90,
                                align: 'left',
                                x: 0,
                                y: -3,
                                style: {
                                    fontSize: '12px',
                                    textShadow: 'none'
                                },
                                formatter: function () {
                                    return Highcharts.numberFormat(this.y, -1, ',', '.');
                                },
                                crop: false,
                                overflow: 'none'
                            },
                            pointPadding: 0.1,
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        this.$router.push({ path: this.buildRoute([{ parameterName: 'as', value: this.UTF8ToB64(e.point.series.userOptions.value) }, { parameterName: 'p', value: '' }]) });
                                    }
                                }
                            },
                            maxPointWidth: '20'
                        }
                    },
                    series: []
                },
                chartOptions2: {
                    chart: {
                        type: 'column',
                        style: {
                            fontFamily: '"Roboto", Helvetica, serif'
                        },
                        zoomType: 'x'
                    },
                    title: {
                        text: this.$gettext('Production by date')
                    },
                    xAxis: {
                        categories: [],
                        labels: {
                            rotation: -90,
                            align: 'right',
                            x: 4,
                            y: 17
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: this.$gettext('Num. activities')
                        },
                        allowDecimals: false,
                        stackLabels: {
                            enabled: true,
                            rotation: -90,
                            align: 'center',
                            x: -6,
                            y: 20,
                            style: {
                                fontSize: '12px',
                                textShadow: 'none'
                            },
                            formatter: function() {
                                return Highcharts.numberFormat(this.total, -1, ',', '.');
                            }
                        }
                    },
                    legend: {
                        enabled: true,
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 1,
                        backgroundColor: '#FFFFFF',
                        shadow: true
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + Highcharts.numberFormat(this.y, -1, ',', '.') + '<br/>' +
                                'Total: ' + Highcharts.numberFormat(this.point.stackTotal, -1, ',', '.');
                        }
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            groupPadding: 0,
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: (e) => {
                                        this.$router.push({ path: this.buildRoute([{ parameterName: 'as', value: this.UTF8ToB64(e.point.series.userOptions.value) }, { parameterName: 'ad', value: this.UTF8ToB64(e.point.category) }, {parameterName: 'p', value: ''}]) });
                                    }
                                }
                            },
                            maxPointWidth: '20'
                        }
                    },
                    series: []
                }
            }
        },
        mounted() {
            // Check if item templates are loaded yet
            if (this.$store.state.templatesLoaded) {
                this.templatesLoaded = true;
                this.init();
            } else {
                // Prepare event to detect templates are loaded
                let vm = this;
                this.$root.$on('templatesLoaded', function () {
                    vm.init();
                    vm.templatesLoaded = true;
                });
            }
        },
        methods: {
            /**
             * Inits graph
             */
            init() {
                // Get data of the item
                this.chartOptions1.series = [];
                this.chartOptions2.xAxis.categories = [];
                this.chartOptions2.series = [];
                this.chartOptions1.lang = this.lang;
                this.chartOptions2.lang = this.lang;
                this.chartOptions1.exporting = this.exporting;
                this.chartOptions2.exporting = this.exporting;
                this.getDataBySubtype();
                this.getDataBySubtypeByYear();
            },
            /**
             * Get data for the subtypes graphic
             */
            getDataBySubtype() {
                // Prepare data to search
                if (this.item != null) {
                    if (this.item.type === 'person') this.formData.query = this.getFieldname('personProfile', 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');
                    else this.formData.query = this.getFieldname(this.item.subtype, 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');
                } else {
                    let queryComponents = [];
                    this.simulation.components.forEach(c => {
                        if (c.type === 'person') queryComponents.push('activity_' + this.$language.current + '_person_str:' + this.filterQuery(c.title) + '##*');
                        else queryComponents.push('activity_' + this.$language.current + '_' + c.type + '_str:' + this.filterQuery(c.title) + '##*');
                    });
                    this.formData.query = '+(' + queryComponents.join(' OR ') + ')';
                }
                this.formData.facets = [{
                    field: this.getFieldname('subtype', 'activity') + '_str',
                    minCount: 1,
                    order: 'index',
                    limit: -1
                }];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet data
                        let facetResponse = response.data.facets.find(rf => rf.length > 0 && rf[0].field.name === 'subtype_str');
                        facetResponse.forEach(frd => {
                            // Determine color
                            let color = '#E2AB17';
                            let itemTemplate = this.$store.state.defaultItemTemplates['activity'][frd.value];
                            if (itemTemplate !== undefined) color = itemTemplate.color;
                            this.chartOptions1.series.push({
                                name: this.$gettext(frd.value),
                                data: [frd.valueCount],
                                color: color,
                                value: frd.value
                            });
                        });

                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Get data for the subtypes / years graphic
             */
            getDataBySubtypeByYear() {
                // Prepare data to search
                if (this.item != null) {
                    if (this.item.type === 'person') this.formData.query = this.getFieldname('personProfile', 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');
                    else this.formData.query = this.getFieldname(this.item.subtype, 'activity') + '_str:' + this.filterQuery(this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '##*');
                } else {
                    let queryComponents = [];
                    this.simulation.components.forEach(c => {
                        if (c.type === 'person') queryComponents.push('activity_' + this.$language.current + '_person_str:' + this.filterQuery(c.title) + '##*');
                        else queryComponents.push('activity_' + this.$language.current + '_' + c.type + '_str:' + this.filterQuery(c.title) + '##*');
                    });
                    this.formData.query = '+(' + queryComponents.join(' OR ') + ')';
                }
                this.formData.pivots = [this.getFieldname('year', 'activity') + '_str', this.getFieldname('subtype', 'activity') + '_str'];

                // Search data
                this.axios.post(this.apiURL + '/items/search', this.formData)
                    .then(response => {
                        // Get facet pivot data data
                        let pivotResponse = response.data.pivots[0];

                        // Get all categories (years)
                        pivotResponse.forEach(pr => {
                            if (!this.chartOptions2.xAxis.categories.includes(pr.value)) this.chartOptions2.xAxis.categories.push(pr.value);
                        });

                        // Create all series
                        pivotResponse.forEach(pr => {
                            pr.pivot.forEach(pp => {
                                // Find or add serie
                                let serie = this.chartOptions2.series.find(s => s.name === pp.value);
                                if (serie === undefined) {
                                    // Determine color
                                    let color = '#E2AB17';
                                    let itemTemplate = this.$store.state.defaultItemTemplates['activity'][pp.value];
                                    if (itemTemplate !== undefined) color = itemTemplate.color;
                                    this.chartOptions2.series.push({
                                        name: pp.value,
                                        data: Array(this.chartOptions2.xAxis.categories.length).fill(0),
                                        color: color,
                                        value: pp.value
                                    });
                                }
                            });
                        });

                        // Recover series data
                        pivotResponse.forEach(pr => {
                            pr.pivot.forEach(pp => {
                                // Find or add series
                                let serie = this.chartOptions2.series.find(s => s.name === pp.value);
                                serie.data[this.chartOptions2.xAxis.categories.findIndex(c => c === pr.value)] = pp.valueCount;
                            });
                        });

                        // Set translated name
                        this.chartOptions2.series.forEach(s => s.name = this.$gettext('subtype_' + s.name));

                        this.loading++;
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        },
        watch: {
            item(newValue, oldValue) {
                if (newValue.id != oldValue.id) {
                    this.loading = -2;
                    this.init();
                }
            }
        }
    };
</script>