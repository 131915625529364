<!--
--------------------------------------------------------------------------
   Item.vue
--------------------------------------------------------------------------

    Component to build item page

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="item" v-if="item !== null">
        <loading v-if="loading"></loading>
        <h1 :class="{ 'mb-0': item.type !== 'activity' }">
            {{ getItemFieldValue(item, 'title', $language.current, false) }}
        </h1>
        <div class="numActivities" v-if="item.type !== 'activity'"><translate>Total activity</translate>: <strong>{{ getItemFieldValue(item, 'numActivities', $language.current, false) }}</strong></div>

        <!-- Item data -->
        <b-card id="item-data" v-if="fieldsLoaded === 0 && viewFields.length > 0">
            <b-card-text>
                <div class="item-image" v-if="item.type === 'person' || item.type === 'organization' || image !== null">
                    <template v-if="image != null && imageLink != null">
                        <a :href="imageLink" target="_blank" rel="nofollow" class="no-icon-after"><b-img thumbnail rounded="0" :src="image" :alt="'Image'|translate"></b-img></a>
                    </template>
                    <b-img thumbnail rounded="0" :src="image" v-else-if="image !== null" :alt="'Image'|translate"></b-img>
                    <b-avatar square size="120px" variant="light" v-else :alt="'Image'|translate" :text="getAvatarText()"></b-avatar>
                </div>
                <div class="item-citations" v-if="itemCitations.length > 0">
                    <b-list-group>
                        <b-list-group-item v-for="itemCitation in itemCitations" :key="'itemCitation.' + itemCitation.id">
                            <strong>{{ itemCitation.title }}</strong><br />
                            <div class="text-center mt-2" v-html="itemCitation.html"></div>
                        </b-list-group-item>
                    </b-list-group>
                </div>
                <div class="item-fields item-data-min" :class="{ 'item-data-margin-left' : item.type === 'person' || item.type === 'organization' || getItemFieldValue(item, 'image', $language.current, false) !== null, 'item-data-margin-right': itemCitations.length > 0 }">
                    <dl class="dl-horizontal">
                        <div v-for="(field, index) in viewFields.filter(vf => vf.values.length > 0)" :key="'field.' + field.name">
                            <div :class="{ 'separator' : index > 0 ? field.view.group !== viewFields[index - 1].view.group : false }" :style="$gettext(field.name).length > 25 ? 'min-height: 50px;' : ''">
                                <dt>
                                    <span v-if="field.view.format !== 'personId' && field.view.format !== 'organizationId'">{{ $gettext(field.name) }}</span>
                                </dt>
                                <dd v-if="field.view.format === 'abstract'">
                                    <div v-if="!showAbstract">
                                        <span v-html="cutWords(field.values)"></span>
                                        <br />
                                        <a href="javascript:void(0);" @click="showAbstract = true" :title="$gettext('View more')">
                                            <i class="fas fa-plus"></i>
                                        </a>
                                    </div>
                                    <div v-else>
                                        <span v-html="field.values"></span>
                                        <br />
                                        <a href="javascript:void(0);" @click="showAbstract = false" :title="$gettext('View less')">
                                            <i class="fas fa-minus"></i>
                                        </a>
                                    </div>
                                </dd>
                                <dd v-else-if="field.view.format === 'person' || field.view.format === 'organization' || field.view.format === 'activity' || field.view.format === 'journal'">
                                    <span v-for="(value, index2) in field.values" :key="'field.' + field.name + '.value.' + index2">
                                        <router-link :to="'/' + value[1]" v-if="value.length > 1 && !value[1].includes('index.')">{{ value[0] + printSubtype(field.view.format, value) }}</router-link>
                                        <router-link :to="relatedItems[value[1].replace('index.', '')]" v-else-if="value.length > 1 && value[1].includes('index.') && relatedItems[value[1].replace('index.', '')] != ''">{{ value[0] }}</router-link>
                                        <span v-html="value[0]" v-else></span>
                                        <span v-html="field.separator"></span>
                                    </span>
                                </dd>
                                <dd v-else-if="field.view.format === 'map'">
                                    <div v-for="(value, index) in field.values" :key="'marker.name.' + index" class="map-marker">
                                        <i class="fas fa-map-marker-alt"></i><sup>{{ index + 1 }}</sup>: {{ value.name }}
                                    </div>
                                    <l-map style="height: 200px; width: 100%;" :zoom="map.zoom" :center="map.center" ref="locationMap" @ready="centerMap()">
                                        <l-tile-layer :url="map.url" :attribution="map.attribution"></l-tile-layer>
                                        <l-marker :lat-lng="[value.lat, value.lng]" v-for="(value, index) in field.values" :key="'marker.' + index" :icon="awesomeMarker(index + 1)">
                                            <l-popup>{{ value.name }}</l-popup>
                                        </l-marker>
                                    </l-map>
                                </dd>
                                <dd v-html="field.values" v-else></dd>
                            </div>
                        </div>
                    </dl>
                </div>
                <router-link :to="$gettext('/personal_corner')" class="fill-data" :class="{ 'fill-data-margin' : item.type === 'person' || item.type === 'organization' || item.image !== undefined }" rel="nofollow" v-if="(item.type === 'person' || item.subtype === 'researchGroup' || item.subtype === 'department') && this.$store.state.user.username != ''">
                    <span v-if="item.type === 'person'"><translate>Fill in your details</translate></span>
                    <span v-else><translate>Fill in details (researchers in charge only)</translate></span>
                </router-link>
                <a :href="this.apiURL + '/users/login?returnUrl=' + encodeURI(this.$store.state.config.URL + $gettext('/personal_corner'))" :class="{ 'fill-data-margin' : item.type === 'person' || item.type === 'organization' || item.image !== undefined }" rel="nofollow" v-else-if="(item.type === 'person' || item.subtype === 'researchGroup' || item.subtype === 'department') && this.$store.state.user.username == ''">
                    <span v-if="item.type === 'person'"><translate>Fill in your details</translate></span>
                    <span v-else><translate>Fill in details (researchers in charge only)</translate></span>
                </a>
            </b-card-text>
            <template #footer>
                <itemProfileTools :item="item" :fields="viewFields"></itemProfileTools>
            </template>
        </b-card>

        <!-- Staff -->
        <itemStaff :item="item" v-if="item !== null && item.type === 'organization' && item.subtype != 'campus'"></itemStaff>

        <!-- Additional data -->
        <itemAdditionalData :item="item" v-if="item !== null && (item.type === 'organization' || item.type === 'person') && showAdditionalData"></itemAdditionalData>

        <!-- Participants -->
        <itemParticipants :item="item" v-if="item !== null && item.type === 'activity'"></itemParticipants>

        <!-- Files -->
        <itemFiles :item="item" v-if="item !== null && item.type === 'activity'"></itemFiles>

        <!-- Scientific production related to item -->
        <items :item="item" :section="null" :searchType="null" :simulation="null" :showFeatured="showFeatured" :featuredDefault="featuredDefault" :showTitle="item.type != 'activity' ? false : true" v-if="item !== null && (item.type != 'activity' || item.subtype == 'participacioProjecteRDICompetitiu')"></items>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    import ItemProfileTools from '@/components/ItemProfileTools';
    import ItemAdditionalData from "@/components/ItemAdditionalData";
    import ItemStaff from "@/components/ItemStaff";
    import ItemParticipants from "@/components/ItemParticipants";
    import ItemFiles from "@/components/ItemFiles";
    import Items from '@/components/Items';
    import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
    import 'leaflet/dist/leaflet.css';
    import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
    import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.js';
    import L from "leaflet";

    export default {
        name: 'item',
        components: { ItemAdditionalData, Loading, ItemProfileTools, ItemStaff, ItemParticipants, ItemFiles, Items, LMap, LTileLayer, LMarker, LPopup },
        data() {
            return {
                loading: true,
                item: null,
                allFields: [],
                fieldsLoaded: -1,
                viewFields: [],
                currentGroup : 1,
                showAbstract: false,
                showAdditionalData: true,
                showFeatured: false,
                featuredDefault: false,
                image: null,
                imageLink: null,
                collaborativeNetworks: [],
                relatedItems: [],
                indexRelatedItems: 0,
                map: {
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    zoom: 15,
                    center: [51.505, -0.159],
                    markers: []
                },
                itemCitations: []
            }
        },
        mounted() {
            this.init();

            let vm = this;
            this.$root.$on('showAdditionalData', function(value) {
                vm.showAdditionalData = value;
            })
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Reset item
                this.item = null;

                // Get item id
                let itemId = this.$route.params.itemId.split('/');

                // Get collaborative networks
                this.axios.get(this.apiURL + '/collaborativeNetworks')
                    .then(response => {
                        this.collaborativeNetworks = response.data;

                        // Find item
                        this.findItem(itemId[0]);
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Finds item by string id
             */
            findItem(itemId) {
                // Get names of identifier fields
                let identifiers = [...new Set(this.$store.state.fields.filter(f => f.ident !== 0).map(f => {
                    if (this.specialFields.includes(f.name)) return f.name;
                    else return this.getFieldname(f.name, f.itemType);
                }))];

                // Prepare search query
                let formData = { query: ['accessURL_str:(' + this.filterQuery(this.$store.state.config.URL + '/' + itemId + '##*') + ' OR ' + this.filterQuery(this.$store.state.config.URL + '/' + itemId + '##*') + ')'], facets: [], page: { pageIdx: 0, pageSize: 2 }, sort: 'subtype desc' };
                identifiers.forEach(i => formData.query.push(i + '_str:"' + this.filterQuery(itemId) + '"'));
                formData.query = formData.query.join(' OR ');

                // Search items
                this.axios.post(this.apiURL + '/items/search', formData)
                    .then(response => {
                        // Item found
                        if (response.data.content.length > 0) {
                            // Get item data
                            this.item = response.data.content[0];

                            // Save current item
                            this.$store.commit('setCurrentItem', this.item);

                            // Set featured flags
                            if (this.item.type === 'person') {
                                this.showFeatured = (this.getItemFieldValue(this.item, 'showFeatured', this.$language.current, false) === '1' ? true : false);
                                this.featuredDefault = (this.getItemFieldValue(this.item, 'featuredDefault', this.$language.current, false) === '1' ? true : false);
                            } else  {
                                this.showFeatured = false;
                                this.featuredDefault = false;
                            }

                            // Set canonical link
                            this.setCanonicalLink();

                            // Set breadcrumbs
                            this.setBreadcrumbs();

                            // Set metadata
                            this.setItemMetadata()

                            // Get item fields
                            this.allFields = this.$store.state.fields.filter(f => f.itemType === this.item.type);
                            this.viewFields = [];
                            this.allFields.forEach(f => {
                                // Check if view data is for current subtype
                                let viewField = f.detail.find(v => v.subtype === this.item.subtype && v.view !== undefined);
                                if (viewField !== undefined) {
                                    f.view = viewField.view;
                                    f.values = this.getItemFieldValue(this.item, f.name, this.$language.current, true);
                                    if (f.values !== null && f.values.find(v => v != null && v != '') !== undefined) {
                                        if (!f.showedByUser || (f.showedByUser && this.showField(f.name))) {
                                            f.values = this.processFieldValues(f);
                                            this.viewFields.push(f);
                                        }
                                    }
                                }
                            });

                            // Sort fields
                            this.viewFields.sort(function (a, b) {
                                if (a.view.group > b.view.group) return 1;
                                else if (a.view.group < b.view.group) return -1;
                                else return a.view.ord > b.view.ord ? 1 : -1;
                            });
                            this.fieldsLoaded = 0;

                            // Prepare item image
                            this.image = this.getItemFieldValue(this.item, 'image', this.$language.current, false);
                            this.imageLink = this.getItemFieldValue(this.item, 'imageLink', this.$language.current, false);
                            if (this.image !== null && this.image.indexOf('http') === -1) this.image = this.$store.state.config.URL + '/images/items/' + this.image;

                            // Add scripts
                            this.addScripts();

                            // Prepare citations
                            this.itemCitations = [];
                            if (this.item.type === 'activity') this.prepareItemCitations();

                            this.loading = false;
                        // Item not found, redirect to to not found
                        } else this.$router.replace({path: '/notFound'});
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            },
            /**
             * Gets avatar text
             */
            getAvatarText() {
                let text = '';

                if (this.item.type === 'organization') text = this.getItemFieldValue(this.item, 'acronym', this.$language.current, false);
                else {
                    let words = this.getItemFieldValue(this.item, 'title', this.$language.current, false);
                    words = words.split(' ');
                    for (let i = 0; i < words.length - 1; i++) text += words[i][0];
                    text = words[words.length - 1][0] + text;
                }

                return text;
            },
            /**
             * Process item field values
             */
            processFieldValues(field) {
                // Get values
                let values = field.values;

                // Format values
                if (field.view.format === 'abstract') {
                    // Delete duplicates
                    let uniqueValues = new Set(values);
                    values = [...uniqueValues];
                } else if (field.view.format === 'boolean') {
                    values = values.map(v => {
                        if (v === 1) return this.$gettext('Yes');
                        else return this.$gettext('No');
                    });
                } else if (field.view.format === 'collaborativeNetwork') {
                    values = values.map(v => {
                        let valueParts = v.replaceAll('###', '##').split('##');
                        if (valueParts[1] === '') valueParts[1] = 'Web';
                        let image = this.collaborativeNetworks.find(cn => cn.name === valueParts[1]).image;
                        return '<a href="' + this.formatURL(valueParts[0]) + '" target="_blank" class="not-icon-after" rel="nofollow" title="' + valueParts[1] + '"><img src="' + this.$store.state.config.URL + '/images/' + image + '" alt="' + valueParts[1] + '"></a> <a href="' + this.formatURL(valueParts[0]) + '" target="_blank" rel="nofollow">' + valueParts[1] + '</a>';
                    });
                } else if (field.view.format === 'doi') {
                    values = values.map(v => '<a href="' + this.$store.state.config.doiURL.replace('{doi}', v) + '" alt="' + this.$gettext('DOI') + '" title="' + this.$gettext('DOI') + '" target="_blank">' + v + '</a>');
                } else if (field.view.format === 'mail') {
                    values = values.map(v => v.replace('@', '<i class="fas fa-at"></i>'));
                } else if (field.view.format === 'isbnIssn') {
                    values = values.map(v => '<a href="' + this.$store.state.config.libraryCatalogISBNISSNURL.replace('{isbnIssn}', v) + '" alt="' + this.$gettext('ISBN-ISSN') + '" title="' + this.$gettext('ISBN-ISSN') + '" target="_blank" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'person' || field.view.format === 'organization' || field.view.format === 'activity' || field.view.format === 'journal') {
                    let vm = this;
                    values = values.map(v => {
                        let valueParts = v.split('##');
                        if (valueParts.length == 1) {
                            // Find item URL (asyncronous)
                            valueParts.push('index.' + vm.indexRelatedItems);
                            vm.relatedItems.push('');
                            vm.findItemURLByTitle(v, field.view.format, vm.indexRelatedItems);
                            vm.indexRelatedItems++;
                        }
                        return valueParts;
                    });
                } else if (field.view.format === 'location') {
                    values = values.map(v => '<a href="' + this.$store.state.config.googleMapsURL.replace('{address}', v) + '" alt="' + this.$gettext('Location') + '" title="' + this.$gettext('Location') + '" target="_blank" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'orcid') {
                    values = values.map(v => '<a href="' + this.$store.state.config.orcidURL.replace('{orcid}', v) + '" alt="' + this.$gettext('ORCID') + '" title="' + this.$gettext('ORCID') + '" target="_blank" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'organizationId') {
                    values = values.map(v => '<a href="' + this.$store.state.config.organizationContactURL.replace('{organizationId}', v) + '" alt="' + this.$gettext('Contact data') + '" title="' + this.$gettext('Contact data') + '" target="_blank" rel="nofollow">' + this.$gettext('Contact data') + '</a>');
                } else if (field.view.format === 'personId') {
                    values = values.map(v => '<a href="' + this.$store.state.config.personContactURL.replace('{personId}', v) + '" alt="' + this.$gettext('Contact data') + '" title="' + this.$gettext('Contact data') + '" target="_blank" rel="nofollow">' + this.$gettext('Contact data') + '</a>');
                } else if (field.view.format === 'isiwok') {
                    values = values.map(v => '<a href="' + this.$store.state.config.wokURL.replace('{isiwok}', v) + '" alt="' + this.$gettext('Researcher ID - Publons') + '" title="' + this.$gettext('Researcher ID - Publons') + '" target="_blank" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'scopus') {
                    values = values.map(v => '<a href="' + this.$store.state.config.scopusURL.replace('{scopusId}', v) + '" alt="' + this.$gettext('Scopus Author ID') + '" title="' + this.$gettext('Scopus Author ID') + '" target="_blank" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'search') {
                    values = values.map(v => '<a href="/search/t/' + this.UTF8ToB64('"' + v + '"') + '" title="' + this.$gettext('Search') + '" alt="' + this.$gettext('Search') + '" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'subject') {
                    values = values.map(v => '<a href="/search/t/' + this.UTF8ToB64('"' + v + '"') + '" title="' + this.$gettext('Search') + '" alt="' + this.$gettext('Search') + '" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'titleLink') {
                    values = values.map(v => {
                        let valueParts = v.split('##');
                        if (valueParts.length > 1) return '<a href="' + this.formatURL(valueParts[1]) + '" title="' + valueParts[0] + '" alt="' + valueParts[0] + '" title="' + valueParts[0] + '" target="_blank" rel="nofollow">' + valueParts[0] + '</a>';
                        else return '<a href="' + this.formatURL(valueParts[0]) + '" title="' + valueParts[0] + '" alt="' + valueParts[0] + '" title="' + valueParts[0] + '" target="_blank" rel="nofollow">' + valueParts[0] + '</a>';
                    });
                } else if (field.view.format === 'url') {
                    values = values.map(v => '<a href="' + this.formatURL(v) + '" title="' + this.$gettext('Link') + '" alt="' + this.$gettext('Link') + '" target="_blank" rel="nofollow">' + v + '</a>');
                } else if (field.view.format === 'text') {
                    values = values.map(v => v.replace(/\n/, '<br />'));
                    values = [...new Set(values)];
                } else if (field.view.format === 'map') {
                    // Process map markers
                    let found = false;
                    values = values.map(v => {
                       if (v.includes('east=') && v.includes('north=')) {
                           let valueParts = v.split(";");
                           let newValue = { lat: 0, lng: 0, name: ''};
                           valueParts.forEach(vp => {
                              if (vp.includes('name=')) newValue.name = vp.replace('name=', '').trim();
                              if (vp.includes('east=')) newValue.lng = vp.replace('east=', '').trim();
                              if (vp.includes('north=')) newValue.lat = vp.replace('north=', '').trim();
                           });
                           found = true;
                           return newValue;
                       }
                    });

                    // If not found any coordinates for markers, set values array to empty
                    if (!found) values = [];
                    else this.map.markers = values;
                }

                return (field.view.format === 'person' || field.view.format === 'organization' || field.view.format === 'activity' || field.view.format === 'journal' || field.view.format === 'map') ? values : values.join(field.separator);
            },
            /**
             * Sets the breadcrumbs
             */
            setBreadcrumbs() {
                let breadcrumbs = [{ text: this.$gettext('Home'), to: '/' }];

                // Add item
                breadcrumbs.push({ text: this.getItemFieldValue(this.item, 'title', this.$language.current, false), active: true });

                // Send breadcrumbs to store
                this.$store.commit('setBreadcrumbs', breadcrumbs);
            },
            /**
             * Set canonical link
             */
            setCanonicalLink() {
                // Get item access URLs
                let canonicalLink = document.createElement('link');
                canonicalLink.setAttribute('rel', 'canonical');
                canonicalLink.setAttribute('href', this.getItemURL(this.item));
                document.head.appendChild(canonicalLink);
            },
            /**
             * Add scripts to head
             */
            addScripts() {
                // Add mathjax
                /*
                let mathJaxScript = document.createElement('script');
                mathJaxScript.setAttribute('src', '//cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js');
                mathJaxScript.setAttribute('data-usercentrics', 'JSDelivr');
                mathJaxScript.setAttribute('async', 'async');
                mathJaxScript.setAttribute('id', 'MathJax-script');
                document.head.appendChild(mathJaxScript);
                 */

                // Add altmetrics script
                let altmetricsScript = document.createElement('script');
                altmetricsScript.setAttribute('src', 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js');
                document.head.appendChild(altmetricsScript);
            },
            /**
             * Centers map
             **/
            centerMap() {
                let groupMarkers = [];
                this.map.markers.forEach(m => groupMarkers.push([m.lat, m.lng]));
                this.$refs.locationMap[0].mapObject.fitBounds(groupMarkers);
                if (groupMarkers.length == 1) this.$refs.locationMap[0].mapObject.setZoom(8);
            },
            /**
             * Custom map icons
             **/
            awesomeMarker(index) {
                return L.AwesomeMarkers.icon({ icon: ' ', markerColor: 'darkblue', prefix: '', html: index });
            },
            /**
             * Prepare item citations
             **/
            prepareItemCitations() {
                // Open citations
                // The citations from WoS are no longer being displayed due to API limitations.
                let numCitations = this.getItemFieldValue(this.item, 'numCitations', this.$language.current, false);
                if (numCitations !== null && numCitations > 0) this.itemCitations.push({ id: 'openCitations', title: 'Open citations', html: this.$gettext('Cited') + ' ' + numCitations + ' ' + this.$gettext('times') });

                // Scopus citations
                let field = this.viewFields.find(f => f.view.format === 'doi');
                let doiValue = null;
                if (field !== undefined) {
                    doiValue = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                    if (doiValue !== null) {
                        this.checkScopusCitations(doiValue);

                        // Modify open citations entry to add link to Open Citations web
                        let index = this.itemCitations.findIndex(ic => ic.id = 'openCitations');
                        if (index !== -1) {
                            this.itemCitations[index].html = '<a href="' + this.$store.state.config.openCitationsURL.replace('{doi}', encodeURI(doiValue)) + '" target="_blank">' + this.itemCitations[index].html + '</a>';
                        }
                    }
                }

                // Scopus sources and google citations
                let issn = this.getItemFieldValue(this.item, 'journalISSN', this.$language.current, false);
                if (issn !== null) {
                    // Google citations
                    let title = this.getItemFieldValue(this.item, 'title', this.$language.current, false);
                    this.itemCitations.push({ id: 'googleScholar', title: 'Google Scholar', html: '<a href="' + this.$store.state.config.googleScholarCitationsURL.replace('{title}', encodeURI(title)) + '" target="_blank">' + this.$gettext('Check citations') + '</a>' });

                    // Scopus sources
                    let year = new Date().getFullYear();
                    this.itemCitations.push({ id: 'scopusSources', title: 'Scopus preview sources', html: '<a href="' + this.$store.state.config.scopusSourcesURL.replace('{issn}', issn).replace('{year}', year) + '" target="_blank">' + this.$gettext('Citescore and other metrics') + '</a>' });
                }

                // Classification
                /*
                let classification = this.getItemFieldValue(this.item, 'classification', this.$language.current, false);
                if (classification !== null) {
                    classification = classification.split(' - ');
                    // Only add Scopus index (SJR)
                    if (classification[0].startsWith('SJR')) {
                        this.itemCitations.push({ id: 'classification', title: this.$gettext('Scimago index') + ' (' + classification[2].trim() + ')', html: classification[3].trim() });
                        this.itemCitations.push({ id: 'quartile', title: this.$gettext('Quartile'), html: 'Q' + classification[4].trim() });
                    }
                }
                 */

                // Altmetric
                if (doiValue != null) this.itemCitations.push({ id: 'altmetric', title: 'Altmetric', html: '<div data-badge-popover="right" data-badge-type="donut" data-doi="' + doiValue + '" class="altmetric-embed"></div>' });
            },
            /**
             * Check scopus citations
             */
            checkScopusCitations(doiValue) {
                let vm = this;
                this.axios.get(this.$store.state.config.scopusCitationsURL.replace('{doi}', doiValue), { headers: { 'X-ELS-APIKey': this.$store.state.config.scopusCitationsKey } })
                    .then(response => {
                        let numCitationsScopus = response.data['search-results']['entry'][0]['citedby-count'];
                        let citationsScopusURL = '';
                        if (numCitationsScopus > 0) {
                            citationsScopusURL = response.data['search-results']['entry'][0]['link'][3]['@href'];
                            // Check open citations exists or not
                            if (this.itemCitations[0].id != 'openCitations') this.itemCitations.unshift({ id: 'scopusCitations', title: 'Scopus', html: '<a href="' + citationsScopusURL + '" target="_blank" rel="nofollow" title="' + vm.$gettext('Citations in Scopus') + '">' + vm.$gettext('Cited') + ' ' + numCitationsScopus + ' ' + vm.$gettext('times') + '</a>' });
                            else this.itemCitations.splice(1, 0, { id: 'scopusCitations', title: 'Scopus', html: '<a href="' + citationsScopusURL + '" target="_blank" rel="nofollow" title="' + vm.$gettext('Citations in Scopus') + '">' + vm.$gettext('Cited') + ' ' + numCitationsScopus + ' ' + vm.$gettext('times') + '</a>' });
                        }
                    })
                    .catch(() => {
                        // Ignore error
                    });
            },
            /**
             * Checks if a field has to be shown
             */
            showField(fieldname) {
                let value = '';

                // Get field value
                fieldname = fieldname + '_show';
                if (this.item[fieldname] !== undefined) {
                    if (Array.isArray(this.item[fieldname])) value = this.item[fieldname][0];
                    else value = this.item[fieldname];
                } else {
                    if (Array.isArray(this.item.secondaryFields[this.item.type + '_' + this.$language.current + '_' + fieldname])) value = this.item.secondaryFields[this.item.type + '_' + this.$language.current + '_' + fieldname][0];
                    else value = this.item.secondaryFields[this.item.type + '_' + this.$language.current + '_' + fieldname];
                }

                return (value === '1');
            },
            /**
             * Finds the url of an item by item title
             */
            findItemURLByTitle(title, type, index) {
                let formData = { query: '', facets: [], page: { pageIdx: 0, pageSize: 1 } };
                if (type === 'person') formData.query = '+person_' + this.$language.current + '_title_str:' + this.filterQuery(title);
                else if (type === 'organization') formData.query = '+organization_' + this.$language.current + '_title_str:' + this.filterQuery(title);
                else if (type === 'activity') formData.query = '+activity_' + this.$language.current + '_title_str:' + this.filterQuery(title);
                else formData.query = '+journal_' + this.$language.current + '_title_str:' + this.filterQuery(title);

                // Search item
                this.axios.post(this.apiURL + '/items/search', formData)
                    .then(response => {
                        // Item found
                        if (response.data.content.length === 1) {
                            // Get item data
                            let item = response.data.content[0];
                            this.$set(this.relatedItems, index, this.getItemURL(item).replace(this.$store.state.config.URL, ''));
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            /**
             * Sets item page metadata
             */
            setItemMetadata() {
                let title = this.getItemFieldValue(this.item, 'title', this.$language.current, false);
                let description = '';
                let keywords = '';

                // Get metadata related to item type
                if (this.item.type === 'person') {
                    description = title;
                    keywords = this.getItemFieldValue(this.item, 'expertise', this.$language.current, true);
                    if (keywords != null) keywords = keywords.join(',');
                    else keywords = this.$gettext('person');
                } else if (this.item.type === 'organization') {
                    description = this.getItemFieldValue(this.item, 'goals', this.$language.current, false);
                    if (description == null) description = title;
                    keywords = this.getItemFieldValue(this.item, 'keywords', this.$language.current, true);
                    if (keywords != null) keywords = keywords.join(',');
                    else keywords = this.$gettext(this.item.subtype);
                } else if (this.item.type === 'activity') {
                    description = this.getItemFieldValue(this.item, 'description', this.$language.current, false);
                    if (description == null) description = title;
                    keywords = this.getItemFieldValue(this.item, 'keywords', this.$language.current, true);
                    if (keywords != null) keywords = keywords.join(',');
                    else keywords = this.$gettext(this.item.subtype);
                } else {
                    description = title;
                    let additionalTitle = this.getItemFieldValue(this.item, 'additionalTitle', this.$language.current, true);
                    if (additionalTitle != null) description += '. ' + additionalTitle.join('. ');
                }

                // Set metadata
                this.setMetadata(title + ' | ' + this.$gettext(this.$store.state.metadata.title), this.decodeHTML(description.substring(0, 200)), keywords + ',' + this.$gettext(this.$store.state.metadata.keywords));
            },
            /**
             * Prints researh group subtype
             */
            printSubtype(format, value) {
                let output = '';

                // Check field format and type
                if (format === 'organization' && value[2] === 'researchGroup' && value[3]) {
                    // Get subtypes values
                    let values = value[3].split(',');
                    let subtypes = [];
                    // Translate subtypes and filter researchGroup subtype
                    for (let i = 0; i < values.length; i++) {
                        if (values[i] !== 'researchGroup') subtypes.push(this.$gettext(values[i]));
                    }

                    // Join values
                    if (subtypes.length > 0) output = ' (' + subtypes.join(', ') + ')';

                }

                return output;
            }
        },
        watch: {
            /**
             * Monitoring route change to update parameters and search results
             */
            $route(from, to) {
                // If item id changes, then re-init data
                if (from.params.itemId !== to.params.itemId) this.init();
            }
        }
    }
</script>