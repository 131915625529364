<!--
--------------------------------------------------------------------------
   ItemAdditionalData.vue
--------------------------------------------------------------------------

    Component to print the item additional data

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-additional-data">
        <b-tabs content-class="mt-3" justified class="mt-5">
            <b-tab active lazy>
                <template #title>
                    <i class="fas fa-chart-bar"></i> <translate>Graphic summary</translate>
                </template>
                <ItemGraph :item="item" :simulation="null"></ItemGraph>
            </b-tab>
            <!--
            <b-tab lazy v-if="item.type === 'person' || item.subtype === 'researchGroup'">
                <template #title>
                    <i class="far fa-handshake"></i> <translate>Collaboration network</translate>
                </template>
                <ItemCollaborationNetwork :item="item"></ItemCollaborationNetwork>
            </b-tab>
            -->
            <b-tab :title-item-class="hitsClass" v-if="item.type === 'person' || item.type === 'organization'">
                <template #title>
                    <i class="fas fa-eye"></i> <translate>Hits</translate>
                </template>
                <ItemHits :item="item"></ItemHits>
            </b-tab>
            <b-tab :title-item-class="newsClass" v-if="item.type === 'person' || item.type === 'organization'">
                <template #title>
                    <i class="far fa-newspaper"></i> <translate>Press room</translate>
                </template>
                <ItemNews :item="item"></ItemNews>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import ItemGraph from "@/components/ItemGraph";
    import ItemNews from "@/components/ItemNews";
    import ItemHits from "@/components/ItemHits";
    //import ItemCollaborationNetwork from "@/components/ItemCollaborationNetwork";

    export default {
        name: 'item-additional-data',
        props: ['item'],
        //components: { ItemGraph, ItemNews, ItemHits, ItemCollaborationNetwork },
        components: { ItemGraph, ItemNews, ItemHits },
        data() {
            return {
                newsClass: 'd-none',
                hitsClass: 'd-none'
            }
        },
        created() {
            // Events to show hits and news, if exists
            let vm = this;
            this.$root.$on('showHits', function() { vm.changeHitsClass() });
            this.$root.$on('showNews', function() { vm.changeNewsClass() });
        },
        methods: {
            /**
             * Change hits tab class to show hits
             */
            changeHitsClass() {
                this.hitsClass = '';
            },
            /**
             * Change news tab class to show hits
             */
            changeNewsClass() {
                this.newsClass = '';
            }
        }
    }
</script>